import type { LoaderFunctionArgs, MetaFunction } from "@remix-run/node";
import { Outlet, json, redirect } from "@remix-run/react";
import { checkAuth, commitSession, getSession } from "~/session.server";
import { feedDummyData } from "./components/feedDummyData";
import { DummyBoard } from "./components/DummyBoard";
import { decorateWorkflowType, isValidationWorkflow, parseParams } from "./utils/workflow-type";
import { SIGNUP_QUERY_PARAMS } from "./types/signup";

export const meta: MetaFunction = () => {
  return [
    { title: "Sign up - Ninjalerts" },
    { name: "description", content: "Welcome to Ninjalerts!" },
  ];
};

export async function loader({ request }: LoaderFunctionArgs) {
  const { workflowType } = parseParams(request).getWorkflowType().extract();

  const auth = await checkAuth(request);

  if (isValidationWorkflow(workflowType)) {
    const session = await getSession(request.headers.get("Cookie"));
    const discordId = session.get(SIGNUP_QUERY_PARAMS.DISCORD_ID);
    if (!discordId) {
      return redirect("/validation-error");
    }

    if (auth.user)
      return redirect(decorateWorkflowType("/signup-complete/wallet-connect", workflowType), {
        headers: { "Set-Cookie": await commitSession(session) },
      });
  } else {
    if (auth.user) return redirect("/boards");
  }

  return json({});
}

export { ErrorBoundary } from "~/components/RemixErrorBoundary";

export default function SignUp() {
  return (
    <div>
      <div className="w-full h-screen bg-no-repeat bg-primary-background">
        <DummyBoard items={feedDummyData} />
        <div className="w-full h-screen bg-primary-foreground/30 backdrop-blur-[20px] absolute top-0 left-0 z-20">
          <div className="h-screen p-5 overflow-y-auto">
            <div className="sm:h-full flex items-center justify-center">
              <div className="bg-tertiary h-[calc(100vh-40px)] w-full sm:w-[460px] sm:h-[712px] rounded-xl shadow-lg p-4 sm:p-5 flex items-end justify-center">
                <Outlet />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
